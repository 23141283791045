import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import {FontLoader} from 'three/examples/jsm/loaders/FontLoader.js'
import {TextGeometry} from 'three/examples/jsm/geometries/TextGeometry.js'


/**
 * Base
 */
// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()


/**
 * Loaders
 */
const image = new Image()
const boxTexture = new THREE.Texture(image)
image.addEventListener('load', () =>
{
    boxTexture.needsUpdate = true
    boxTexture.magFilter = THREE.NearestFilter
})
image.src = '/textures/minecraft.png'

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()
const matCapTexture = textureLoader.load('/textures/matcaps/2.png')


/**
 * Fonts
 */

 const fontloader = new FontLoader() 
 fontloader.load(
    '/fonts/Minecrafter_Alt_Regular.json',
    (font) =>
    {
        const textGeometry = new TextGeometry(
            'Tommy Exogenie',
            {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 5,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 4
            }
        )
        
        textGeometry.center()
        
        const material1 = new THREE.MeshMatcapMaterial({matcap: matCapTexture})
        const material2 = new THREE.MeshMatcapMaterial({map: boxTexture})
        //textMaterial.matcap = matCapTexure
        //textMaterial.wireframe = true
        const text = new THREE.Mesh(textGeometry, material1)
        scene.add(text)

        //Create the mesh and material outside of the loop    
        //const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 20, 45)
        const mcBoxGeometry = new THREE.BoxGeometry(1, 1, 1, 2, 2, 2)

        for(let i = 0; i < 100; i++) {
            const mcBox = new THREE.Mesh(mcBoxGeometry, material2)
            mcBox.position.x = (Math.random() - 0.5) * 10
            mcBox.position.y = (Math.random() - 0.5) * 10
            mcBox.position.z = (Math.random() - 0.5) * 10
            mcBox.rotation.x = Math.random() * Math.PI
            mcBox.rotation.y = Math.random() * Math.PI
            const scale = Math.random() / 2.5
            mcBox.scale.set(scale, scale, scale)
            scene.add(mcBox)
        }
     }
 )



 // Axis helper
 const axisHelper = new THREE.AxesHelper()
 //scene.add(axisHelper)


/**
 * Objects
 */


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = -2
camera.position.y = -2
camera.position.z = 5
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update objects
    camera.position.x += 0.001
    camera.position.y += 0.001
    camera.rotation.y += 0.001

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()